import React from 'react'
import { Row, Col } from "antd";
import "./css/roadmap.css";

export const Roadmap = () => {
    return (
        <Row xs={24} id="roadmap">
            <Col xs={20} md={16}>
                <h1 className='title-section mb-0'>ROADMAP</h1>
            </Col>
            <Col xs={20} md={16}>
                <h1 className='primary-text title-text mb-0'>
                    SLITHERING TO SUCCESS 
                </h1>
            </Col>
            <Col xs={24} className="card-wrap">
                <div className='card-custom'>
                    <h1 className='primary-text title-text mb-0'>
                        Phase 1
                    </h1>
                    <h3 className='des-text mb-0'>
                        • Website launch <br />
                        • Community & Socials growth  <br />
                        • Smart Contract Design <br />
                        • Partners & Incubators <br />
                        • Audit Contract <br />
                        • Subsciption Presale
                    </h3>
                </div>

                <div className='card-custom'>
                    <h1 className='primary-text title-text mb-0'>
                        Phase 2
                    </h1>
                    <h3 className='des-text mb-0'>
                        • Post launch influencer marketing <br />
                        • Apply for listings (CG, CMC, Crypto.com) <br />
                        • Listed on CMC, CG and Crypto.com <br />
                        • Youtube influencers on board
                    </h3>
                </div>

                <div className='card-custom'>
                    <h1 className='primary-text title-text mb-0'>
                        Phase 3
                    </h1>
                    <h3 className='des-text mb-0'>
                        • Big Marketing push <br />
                        • Trending (dextools, CMC,crypto.com) <br />
                        • Smash through marketcaps goals! <br />
                        • Big Partnerships <br />
                        • CEX listing <br />
                        • Expand more partnerships
                    </h3>
                </div>
            </Col>
        </Row>
    )
}