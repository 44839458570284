import React, { useState } from 'react'
import "./css/navbar.css"
import { Row, Col, Drawer } from "antd";
import { logo, tele, twitter } from "../assets/imgs";
import { LINKS } from "../constants"

export function Navbar() {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Row xs={24} className="menu-wrap">
            <Col xs={24}>
                <Row xs={24} className="desktop nav-wrap">
                    <Col xs={6} className="logo-wrap" >
                        <a href='/'>
                            <img src={logo} alt="logo" />
                        </a>
                    </Col>
                    <Col xs={12} className="link-wrap">
                        <a href={LINKS.HOME}>
                            HOME
                        </a>
                        <a href={LINKS.ABOUT}>
                            ABOUT
                        </a>
                        <a href={LINKS.TOKENOMICS}>
                            TOKENOMICS
                        </a>
                        <a href={LINKS.UTILITY}>
                            UTILITY
                        </a>
                        <a href={LINKS.ROADMAP}>
                            ROADMAP
                        </a>
                        <a href={LINKS.CHART}>
                            CHART
                        </a>
                    </Col>
                    <Col xs={6} className="social-wrap">
                        <a href={LINKS.TELEGRAM} className='icon-wrap' target="blank">
                            <img src={tele} alt="icon" />
                        </a>
                        <a href={LINKS.TWITTER} className='icon-wrap' target="blank">
                            <img src={twitter} alt="icon" />
                        </a>
                    </Col>
                </Row>

                <Row xs={24} className="mobile nav-wrap">
                    <Col xs={6} className="logo-wrap" >
                        <a href='/'>
                            <img src={logo} alt="logo" />
                        </a>
                    </Col>
                    <Col xs={12}>

                    </Col>

                    <Col xs={6} className="menu-icon">
                        <img onClick={showDrawer} src="data:image/svg+xml; charset=utf-8;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUxMiA1MTIiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48bGluZSB4MT0iMjYuNyIgeDI9IjQ4Ni4yNSIgeTE9IjEwNS42NDMiIHkyPSIxMDUuNjQzIiBmaWxsPSJub25lIiBzdHJva2U9IiMzNGE4NTMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIuNjEzIiBzdHJva2Utd2lkdGg9IjEzLjQxNyIgY2xhc3M9ImNvbG9yU3Ryb2tlMDAwMDAwIHN2Z1N0cm9rZSI+PC9saW5lPjxsaW5lIHgxPSIyNi43IiB4Mj0iNDg2LjI1IiB5MT0iMjU2LjUxMSIgeTI9IjI1Ni41MTEiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzM0YTg1MyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMi42MTMiIHN0cm9rZS13aWR0aD0iMTMuNDE3IiBjbGFzcz0iY29sb3JTdHJva2UwMDAwMDAgc3ZnU3Ryb2tlIj48L2xpbmU+PGxpbmUgeDE9IjI2LjciIHgyPSI0ODYuMjUiIHkxPSI0MDcuMzEyIiB5Mj0iNDA3LjMxMiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzRhODUzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIyLjYxMyIgc3Ryb2tlLXdpZHRoPSIxMy40MTciIGNsYXNzPSJjb2xvclN0cm9rZTAwMDAwMCBzdmdTdHJva2UiPjwvbGluZT48L3N2Zz4=" alt="logo" />
                    </Col>

                    <Drawer closable={false} placement="right" open={open}>
                        <Row xs={24} className="drawer-content">
                            <button onClick={onClose}>
                                ✕
                            </button>
                        </Row>
                        <Row xs={24}>
                            <Col xs={24} className="link-wrap">
                                <a href={LINKS.HOME} onClick={onClose}>
                                    HOME
                                </a>
                                <a href={LINKS.ABOUT} onClick={onClose}>
                                    ABOUT
                                </a>
                                <a href={LINKS.TOKENOMICS} onClick={onClose}>
                                    TOKENOMICS
                                </a>
                                <a href={LINKS.UTILITY} onClick={onClose}>
                                    UTILITY
                                </a>
                                <a href={LINKS.ROADMAP} onClick={onClose}>
                                    ROADMAP
                                </a>
                                <a href={LINKS.CHART} onClick={onClose}>
                                    CHART
                                </a>
                            </Col>

                            <Col xs={24} className="social-wrap" style={{ marginTop: '2em' }}>
                                <a href={LINKS.TELEGRAM} className='icon-wrap' target="blank">
                                    <img src={tele} alt="icon" />
                                </a>
                                <a href={LINKS.TWITTER} className='icon-wrap' target="blank">
                                    <img src={twitter} alt="icon" />
                                </a>
                            </Col>
                        </Row>
                    </Drawer>
                </Row>
            </Col>
        </Row>
    )
}