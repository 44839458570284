import React from 'react'
import "./css/partner.css";
import Marquee from "react-fast-marquee";

export const Partner = () => {
    return (
        <div className='partner-wrap' id="partner">
            <h1 className='title-section mb-0'>HALLOWEEN INU WILL BE ON <br/> AND SUPPORT</h1>

            <Marquee className='marquee' gradient={false} speed={60} style={{ margin: '2em 0' }}>
                <div className='partner-card'>
                    <img src="https://www.pngall.com/wp-content/uploads/10/PancakeSwap-Crypto-Logo-PNG-Images.png" width={200} height={100} alt="partner" />
                </div>
                <div className='partner-card'>
                    <img src="https://www.fuma.finance/images/partners/poocoin.png" width={200} alt="partner" />
                </div>
                <div className='partner-card'>
                    <img src="https://trustwallet.com/assets/images/media/assets/horizontal_blue.svg" width={200} alt="partner" />
                </div>
                <div className='partner-card'>
                    <img src="https://www.logo.wine/a/logo/Binance/Binance-Vertical-Logo.wine.svg" width={200} alt="partner" />
                </div>
                <div className='partner-card'>
                    <img src="https://1000logos.net/wp-content/uploads/2022/03/CoinMarketCap-logo.png" width={200} alt="partner" />
                </div>
                <div className='partner-card'>
                    <img src="https://cdn.pixabay.com/photo/2021/06/03/09/04/cardano-6306459_960_720.png" width={200} alt="partner" />
                </div>
            </Marquee>

            <h1 className='primary-text title-text mb-0'>
                PRESALE POWERED BY
            </h1>

            <div className='special-partner'>
                <div className='partner-card'>
                    <img src="https://3927443782-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F-MdJrJV-Z8jLCR-JNtlx%2Fuploads%2FiEWLEOpo5X60d3INULja%2Fpinksale-2000x1000-transparent.png?alt=media&token=a4c9a802-f97b-44ac-9d1b-efa7228cf719" width={200} alt="partner" />
                </div>
            </div>

        </div>
    )
}