import React from "react";
import './App.css';
import { Navbar, Banner, About, Tokenomics, Utility, Roadmap, Partner, Footer } from "./components";

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Banner/>
      <About/>
      <Tokenomics/>
      <Utility/>
      <Roadmap/>
      <Partner/>
      <Footer/>
    </div>
  );
}

export default App;
