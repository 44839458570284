import React from 'react'
import "./css/footer.css";
import { LINKS } from "../constants";
import { tele, twitter } from "../assets/imgs";

export const Footer = () => {
    return (
        <div className='footer-wrap'>
            <h1 className='primary-text title-text'>
                JOIN US NOW
            </h1>

            <h3 className='des-text mb-0'>
                We invite you to join our Telegram group, where you can interact with us, and thousands of other members of the Halloween Inu community.
            </h3>

            <div className='join-tele'>
                <a href={LINKS.TELEGRAM} className='btn-join' target="blank">
                    JOIN TELEGRAM
                </a>
            </div>

            <h3 className='des-text'>
                Follow us on our social media platforms to get the most up-to-date, and accurate Information.
            </h3>

            <div className="social-wrap">
                <a href={LINKS.TELEGRAM} className='icon-wrap'>
                    <img src={tele} alt="icon" />
                </a>
                <a href={LINKS.TWITTER} className='icon-wrap'>
                    <img src={twitter} alt="icon" />
                </a>
            </div>

            <div className='copyright'>
                <small>
                    Copyright © 2022 - Halloween Inu | All Rights Reserved
                </small>
            </div>
        </div>
    )
}