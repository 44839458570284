import React from 'react'
import { Row} from "antd";
import { banner } from "../assets/imgs";
import "./css/banner.css"

export const Banner = () => {
  return (
    <Row xs={24} className="banner-wrap" >
      <img src={banner} width="100%" alt="banner" />
    </Row>
  )
}