import React from 'react'
import { Row, Col } from "antd";
import "./css/tokenomics.css";
import { tokenomics } from "../assets/imgs";

export const Tokenomics = () => {
    return (
        <Row xs={24} id="tokenomics">
            <Col xs={20} md={16}>
                <h1 className='title-section mb-0'>TOKENOMICS</h1>
            </Col>
            <Col xs={20} md={16}>
                <h1 className='primary-text title-text mb-0'>
                    SIMPLE YET POWERFUL TOKENOMICS
                </h1>
            </Col>
            <Col xs={20} md={16}>
                <h3 className='des-text mb-0'>
                    5% on buy goes for marketing which will further help growth of the project in general
                </h3>

                <h3 className='des-text mb-0'>
                    5% on sell goes for utility upgrades. That tax will be used to further upgrade main utility - Halloween Tech
                </h3>
            </Col>
            <Col xs={20} md={16} className="tokenomics-wrap">
                <img width="100%" src={tokenomics} alt="logo" />
            </Col>
        </Row>
    )
}