import React from 'react'
import { Row, Col } from "antd";
import "./css/about.css";
import { logo, transfer } from "../assets/imgs";

export function About() {
    return (
        <Row xs={24} id="about">
            <Col xs={24} className="button-wrap">
                <button className='btn-buy'>
                    BUY NOW {" "}
                    <img width={24} src={transfer} alt="icon" />
                </button>
            </Col>
            <Col xs={20} md={16}>
                <h1 className='primary-text title-text'>
                    Halloween Inu is the next meme trend and will be the next trend! The leader of the Halloween trend.
                    Welcome to Halloween Inu! The scariest project living (or dead) on the
                    bsc network. The obsession starts on October 1st so join us
                    for this spooky holiday season
                </h1>
            </Col>
            <Col xs={20} md={16} className="logo-wrap-about">
                <img width="200" src={logo} alt="logo" />
            </Col>
            <Col xs={20} md={16}>
                <h3 className='des-text'>
                    The original name of Halloween was All Hallows'Eve, which means the eve of All Saints' Day. “Hallow” was an Old English word meaning “holy” and was eventually changed to Halloween as we know it today. Thus Halloween is the result of many transformations over the centuries. But now, the holiday in Halloween no longer has a religious meaning, but everyone considers this a fun festival. Halloween Inu will be launched on the Binance Smart Chain and aims to reach great heights while introducing a unique utility onto the table
                </h3>
                <a href='https://drive.google.com/file/d/1HltjvBFCRDnWrMrGmKlI_AjRB9liBkkI/view?usp=sharing' className='btn-wpp' target="blank">
                    WHITEPAPER
                </a>
            </Col>
        </Row>
    )
}
