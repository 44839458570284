import React from 'react';
import { Row, Col } from "antd";
import "./css/utility.css";
import { utility } from '../assets/imgs';

export const Utility = () => {
    return (
        <Row xs={24} id="utility">
            <Col xs={20} md={16}>
                <h1 className='title-section mb-0'>UTILITY</h1>
            </Col>
            <Col xs={20} md={16}>
                <h1 className='primary-text title-text mb-0'>
                    HALLOWEEN TECH
                </h1>
            </Col>
            <Col xs={20} md={16} className="utility-wrap">
                <img width="40%" src={utility} alt="logo" />
            </Col>
            <Col xs={20} md={16}>
                <h3 className='des-text mb-0'>
                    Halloween Tech is the perfect crypto locator which helps investors to find new crypto projects, presales and ICOS. It takes the form of a cryptocurrency voting platform. The idea behind this is that the voice and opinions of the community are more powerful than the decisions of any single governing body. We do the work for you while you reap the benefits
                </h3>
            </Col>
        </Row>
    )
}